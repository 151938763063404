@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Reset any default margins/padding */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Modern Scrollbar Styles */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #330864;
  border-radius: 3px;
  transition: background 0.2s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: #4a0b91;
}

/* Firefox scrollbar styles */
* {
  scrollbar-width: thin;
  scrollbar-color: #330864 #f1f1f1;
}

/* Ensure proper mobile viewport height */
.min-h-screen {
  min-height: 100vh;
  /* Add this to fix mobile viewport height issues */
  min-height: -webkit-fill-available;
}

/* Fix for mobile browsers */
html {
  height: -webkit-fill-available;
}

/* Main app container styles */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Content container with proper padding for header */
.content-container {
  flex: 1;
  width: 100%;
  height: 100%;
}

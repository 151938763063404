.equipment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
}

.equipment-modal-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 95%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  /* Modern scrollbar styles */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #888 #f1f1f1; /* Firefox */
}

/* Webkit (Chrome, Safari, Edge) scrollbar styles */
.equipment-modal-container::-webkit-scrollbar {
  width: 8px;
}

.equipment-modal-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.equipment-modal-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.equipment-modal-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  z-index: 1;
}

.equipment-header {
  position: relative;
  margin-bottom: 10px;
}

.equipment-info {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.equipment-info h2 {
  margin: 0;
  font-size: 1.4em;
}

.equipment-category {
  color: #888;
  font-size: 0.9em;
}

.equipment-status {
  position: absolute;
  top: 0;
  right: 25px;
  font-weight: bold;
}

.equipment-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.equipment-field-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 5px;
}

.equipment-field-row .equipment-field:first-child {
  flex: 2;
}

.equipment-field-row .equipment-field:last-child {
  flex: 1.5;
  min-width: 150px;
  white-space: nowrap;
}

.equipment-field {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 5px;
}

.equipment-field label {
  font-weight: bold;
  min-width: 55px;
}

.editable-field {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
  min-width: 0;
}

.editable-field input {
  flex: 1;
  padding: 3px 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.edit-actions {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: 4px;
}

.icon {
  cursor: pointer;
  font-size: 16px;
}

.icon.edit {
  color: #888;
}

.icon.save {
  color: #4CAF50;
}

.icon.cancel {
  color: #f44336;
}

/* For inline display */
.equipment-details {
  flex: 2;
  padding: 10px;
  overflow-y: auto;
  background-color: white;
  min-width: 600px;
  position: relative;
  
  /* Modern scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.equipment-details::-webkit-scrollbar {
  width: 8px;
}

.equipment-details::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.equipment-details::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.equipment-details::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.field-value {
  white-space: nowrap;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .equipment-modal-container {
    width: 90%;
    max-width: 500px;
  }
  
  .equipment-details {
    min-width: unset;
  }
}

.meter-checkbox {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.checkbox-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  color: #666;
}

.checkbox-button:hover {
  color: #333;
}

.checkbox-button svg {
  width: 16px;
  height: 16px;
}

.equipment-field {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 5px;
}

.meter-field {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
  position: relative;
}

.meter-value {
  min-width: 60px;
  margin-right: 8px;
}

.meter-input {
  width: 80px;
  padding: 2px 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.meter-actions {
  display: flex;
  gap: 4px;
  align-items: center;
}

.meter-actions .icon {
  cursor: pointer;
  font-size: 16px;
}

.meter-actions .icon.edit {
  color: #888;
}

.meter-actions .icon.save {
  color: #4CAF50;
}

.meter-actions .icon.cancel {
  color: #f44336;
}

.history-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  color: #666;
  margin-left: 4px;
}

.history-button:hover {
  color: #333;
}

.location-select {
  flex: 1;
  padding: 3px 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: inherit;
}

.location-select:focus {
  outline: none;
  border-color: #007bff;
}

.edit-icon-container {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.edit-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
  color: #666;
  display: flex;
  align-items: center;
}

.edit-toggle:hover {
  color: #333;
}

.edit-input {
  flex: 1;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: inherit;
}

.edit-input:focus {
  outline: none;
  border-color: #007bff;
}

.edit-actions {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.save-button, .cancel-button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.save-button {
  background-color: #007bff;
  color: white;
  border: none;
}

.cancel-button {
  background-color: white;
  border: 1px solid #ddd;
}

.save-button:hover {
  background-color: #0056b3;
}

.cancel-button:hover {
  background-color: #f8f9fa;
}

.equipment-type {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Update edit button position */
.equipment-modal-edit {
  position: absolute;
  top: 20px;
  right: 30px; /* Moved from 20px to 30px to avoid scrollbar overlap */
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #666;
  z-index: 2;
}

.equipment-modal-edit:hover {
  color: #333;
}

/* Add media query for mobile devices */
@media (max-width: 576px) {
  .equipment-field-row {
    flex-direction: column;
    gap: 5px;
  }

  .equipment-field-row .equipment-field:first-child,
  .equipment-field-row .equipment-field:last-child {
    flex: 1;
    min-width: unset;
    width: 100%;
  }

  .equipment-field {
    margin-bottom: 8px;
  }

  /* Adjust modal padding for mobile */
  .equipment-modal-container {
    padding: 15px;
    width: 100%;
    max-width: none;
    border-radius: 0;
  }

  /* Adjust field labels for better mobile layout */
  .equipment-field label {
    min-width: 70px;
  }

  /* Ensure input fields take full width */
  .edit-input {
    width: 100%;
  }
}

/* Add these styles */
.status-field {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-field label {
  font-weight: bold;
  color: #666;
}

.status-select {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

/* Update existing equipment-modal-status style */
.equipment-modal-status {
  position: absolute;
  top: 0;
  right: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

/* Add these styles to your existing CSS */

.license-plate-fields {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.license-plate-info {
  margin-bottom: 15px;
}

.log-requirements {
  margin: 15px 0;
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.checkbox-input {
  margin-right: 8px;
}

.log-requirements .equipment-field {
  margin-bottom: 8px;
}

.log-requirements label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Add responsive styles */
@media (max-width: 576px) {
  .license-plate-fields {
    flex-direction: column;
    gap: 10px;
  }
}

.maintenance-schedule-section {
  margin-top: 1rem;
  padding: 1rem;
  border-top: 1px solid #eee;
}

.maintenance-schedule-section .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.maintenance-schedule-section .edit-button,
.maintenance-schedule-section .cancel-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.maintenance-schedule-section .edit-button:hover {
  background-color: #f0f0f0;
}

.maintenance-schedule-section .cancel-button {
  color: #f44336;
}

.maintenance-schedule-section .cancel-button:hover {
  background-color: #fee;
}

.maintenance-schedule-section .icon {
  width: 16px;
  height: 16px;
}

.part-name {
  font-weight: 500;
  display: flex;
  gap: 8px;
}

.part-quantity {
  color: #666;
}

.part-usage {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #666;
}

/* Document Section Styles */
.equipment-documents-section {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.equipment-documents-section .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.equipment-documents-section h3 {
  margin: 0;
  font-size: 1.1em;
  color: #333;
}

.upload-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s;
}

.upload-button:hover {
  background-color: #45a049;
}

.documents-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.document-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
  gap: 12px;
}

.document-icon {
  color: #666;
  font-size: 1.5em;
}

.document-info {
  flex: 1;
}

.document-name {
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.document-details {
  display: flex;
  gap: 15px;
  font-size: 0.85em;
  color: #666;
}

.document-type {
  color: #2196F3;
}

.document-actions {
  display: flex;
  gap: 8px;
}

.action-button {
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
  transition: all 0.2s;
}

.action-button:hover {
  background-color: #e9ecef;
}

.action-button.download:hover {
  color: #2196F3;
}

.action-button.delete:hover {
  color: #dc3545;
}

.no-documents {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}
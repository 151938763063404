.maintenance-schedule-display {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 0;
}

.maintenance-item {
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e2e8f0;
  position: relative;
}

.maintenance-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.schedule-item {
  margin-bottom: 1rem;
}

.schedule-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.maintenance-description {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.previous-service {
  font-size: 0.9rem;
  color: #666;
  margin: 0.25rem 0;
}

.next-due {
  font-size: 0.9rem;
  color: #2c5282;
  font-weight: 500;
}

.service-disabled {
  color: #a0aec0;
  font-style: italic;
}

.service-notes {
  font-size: 0.9rem;
  color: #718096;
  font-style: italic;
}

.service-text {
  color: #2c3e50;
  font-weight: 500;
}

.service-notes {
  color: #666;
  font-style: italic;
}

.service-disabled {
  color: #999;
  text-decoration: line-through;
}

.next-due {
  font-size: 0.9em;
  color: #666;
}

.maintenance-item:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.maintenance-item:active {
  transform: translateY(0);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.section-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #1f2937;
  font-weight: 600;
} 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.edit-equipment-type-modal {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.edit-equipment-type-modal form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal h2 {
  margin-top: 0;
  padding: 20px 20px 0;
}

.modal-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.form-actions {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #ccc;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.form-actions button {
  padding: 8px 15px;
  cursor: pointer;
  font-weight: bold;
}

.categories-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.categories-table-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.categories-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.categories-table-container thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

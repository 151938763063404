.dashboard-container {
    position: relative;
    height: 100vh; /* Full viewport height */
    margin-left: 40px; /* Adjust for sidebar width */
    overflow: hidden;
}

.dashboard-welcome {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5rem;
    color: #333;
}

.dashboard-tabs {
    display: flex;
    position: fixed;
    top: 0;
    left: 40px;
    right: 0;
    height: 40px;
    background-color: #f0f0f0;
    z-index: 100;
}

.dashboard-tab {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dashboard-tab:hover {
    background-color: #e0e0e0;
}

.dashboard-tab.active {
    background-color: #d0d0d0;
}

.dashboard-content {
    position: relative;
    padding: 10px;
    overflow-y: auto;
}

.welcome-section {
    max-width: 1200px;
    margin: 0 auto;
}

.tab-content {
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
}

.driver-section {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
}

.meter-readings {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.maintenance-schedule-container {
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.welcome-message {
    padding: 10px;
    background-color: #fff;
    height: calc(100vh - 80px);
    box-sizing: border-box;
}

.welcome-message h2 {
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 1.5rem;
    color: #333;
}

/* Add these styles */
.create-order-icon {
  transition: color 0.2s ease;
}

.create-order-icon:hover {
  color: #388E3C !important; /* Darker green on hover */
}

/* Add these styles */
.work-order-stats {
  color: #666;
  margin-top: 8px;
  margin-bottom: 16px;
}

.equipment-selection {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.select-group {
  flex: 1;
}

.select-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.select-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.equipment-readings {
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
}

.equipment-readings h4 {
  margin: 0 0 0px 0;
  color: #333;
}

.reading-input {
  margin-bottom: 15px;
}

.reading-input:last-child {
  margin-bottom: 0;
}

.reading-input label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.reading-input input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.update-meters-button {
  align-self: flex-end;
  padding: 8px 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.update-meters-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.truck-readings {
  margin-left: -10px !important;
}

.trailer-readings {
  margin-right: -10px !important;
}

/* If you need to ensure the readings are side by side on larger screens */
.meter-readings {
  display: flex;
  gap: 1rem; /* Maintains spacing between truck and trailer sections */
}

/* Make it stack on mobile */
@media (max-width: 768px) {
  .meter-readings {
    flex-direction: column;
  }
}

.driver-section-title {
  margin-top: 0px;
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 1.25rem;
  font-weight: 600;
}

/* Add media query for mobile */
@media (max-width: 768px) {
    .dashboard-container {
        margin-left: 0; /* Remove sidebar margin on mobile */
    }

    .dashboard-tabs {
        left: 0; /* Remove sidebar offset on mobile */
    }
}

/**
 * ServiceOrderManagement Component Styles
 * ====================================
 * 
 * Styles for the ServiceOrderManagement component, including
 * drag and drop visual feedback.
 */

.draggable-service {
    cursor: grab;
    transition: all 0.2s ease;
}

.draggable-service.dragging {
    opacity: 0.5;
    cursor: grabbing;
}

.draggable-service.drag-over {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  padding: 0;
}

/* Content container - holds the main content */
.content-container {
  display: flex;
  flex: 1;
  position: relative;
  margin-top: 80px; /* header + nav height */
}

/* Main element styles - no padding or margin */
main {
  flex: 1;
  position: relative;
  width: 100%;
  height: calc(100vh - 80px); /* Subtract header + nav height */
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

/* Content wrapper styles */
main > div {
  height: 100%;
  padding: 0;
  margin: 0;
}

/* Header Styles */
header {
  width: 100%;
  height: 40px;
  background-color: #330864;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.left-section {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  margin-left: -10px;
}

.logo {
  margin: 0;
  font-size: 1.2rem;
  color: white;
}

.right-section {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.login-link {
  color: blue;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.login-link:hover, .profile-link:hover {
  color: blueviolet;
}

.logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 40px;
  left: 0;
  height: calc(100vh - 40px);
  background-color: #330864;
  transition: width 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  z-index: 1000;
  width: 40px;
  overflow-y: auto;
}

.sidebar.expanded {
  width: 160px;
}

.menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: background 0.3s, padding-left 0.3s;
  height: 40px;
}

.menu-item:hover {
  background-color: #34495e;
}

.menu-item.active {
  background-color: #0E2C62;
}

.menu-item-icon {
  margin-right: 15px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.menu-item-text {
  white-space: nowrap;
  display: inline-block;
  transition: opacity 0.3s;
  opacity: 0;
}

.sidebar.expanded .menu-item-text {
  opacity: 1;
}

.submenu {
  padding-left: 25px;
  font-size: 0.9rem;
}

/* Form Styles */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

/* Styles for the form containers */
.add-user-form-container,
.edit-user-form-container,
.edit-role-form,
.delete-role-confirmation {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

/* Style for form titles */
.add-user-form-container h2,
.edit-user-form-container h2,
.edit-role-form h2,
.delete-role-confirmation h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
}

/* Style for form inputs */
.add-user-form-container input,
.edit-user-form-container input,
.edit-role-form input,
.edit-role-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Style for form buttons */
.add-user-form-container button,
.edit-user-form-container button,
.edit-role-form button,
.delete-role-confirmation button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

/* Profile Styles */
.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.profile-section {
  flex: 1 1 300px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Equipment Styles */
.equipment-container {
  padding: 20px;
}

.equipment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.equipment-list {
  list-style-type: none;
  padding: 0;
}

.equipment-list li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

/* Work Order Form Styles */
.workorder-form-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.workorder-form-container h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.workorder-form .form-group {
  margin-bottom: 15px;
}

.workorder-form .radio-group {
  display: flex;
  gap: 10px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Add Equipment Styles */
.add-equipment-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.add-equipment-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: left;
  gap: 10px;
}

.half-width {
  flex: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  main {
    padding: 10px;
    margin-left: 0;
  }

  .form-row {
    flex-direction: column;
  }

  .half-width {
    width: 100%;
  }

  .page-title {
    font-size: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Dashboard Styles */
.dashboard-container {
  padding: 20px;
}

.dashboard-content {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.welcome-message {
  margin-bottom: 30px;
}

.welcome-message h2 {
  color: #330864;
  font-size: 2rem;
  margin-bottom: 10px;
}

.welcome-message p {
  font-size: 1.1rem;
  color: #555;
}

.construction-notice {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.construction-icon {
  font-size: 3rem;
  display: block;
  margin-bottom: 15px;
}

.construction-notice p {
  margin: 10px 0;
  color: #856404;
}

/* Settings Styles */
.settings-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.settings-section {
  border-bottom: 1px solid #e2e8f0;
}

.settings-row {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.settings-field {
  flex: 1;
  font-weight: bold;
}

.settings-value {
  flex: 2;
}

.settings-actions {
  flex: 1;
  text-align: right;
}

.settings-actions button {
  margin-left: 0.5rem;
}

.settings-container {
  padding: 20px;
}

.settings-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.settings-list table {
  width: 100%;
  border-collapse: collapse;
}

.settings-list th,
.settings-list td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.sub-header {
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 15px;

  background-color: #f9f9f9;
  font-size: 1.2em;
  font-weight: bold;
}

.button-apply {
  background-color: #4CAF50;
  margin-right: 5px;
}

.button-cancel {
  background-color: #f44336;
}

/* Add these styles or adjust as per your existing styles */

.forgot-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
}

.forgot-button:hover {
  color: #0056b3;
}

.profile-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}

.profile-button:hover {
  background-color: #45a049;
}

.radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.radio-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.radio-group input[type="radio"] {
  margin-right: 10px;
}

.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.switch-container label {
  flex-grow: 1;
}

/* ... (existing styles) */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.workorder-form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.workorder-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 0.5rem;
}

.radio-group {
  display: flex;
  gap: 1rem;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

/* Add responsive styles for smaller screens */
@media (max-width: 768px) {
  .workorder-form-container {
    width: 95%;
    padding: 1rem;
  }
}

/* Instead, use specific class names */
.primary-button {
  background-color: #4CAF50;
  color: white;
}

/* Remove any default padding in mobile view */
@media (max-width: 768px) {
  .app-container,
  .content-container,
  main {
    padding-left: 0;
    padding-right: 0;
  }
  
  main > div {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Dashboard specific styles */
.dashboard-layout main {
  margin-left: 40px;
  transition: margin-left 0.3s;
}

/* Responsive breakpoints matching Tailwind's defaults */
@media (max-width: 768px) {
  .dashboard-layout main {
    margin-left: 0;
  }
  
  /* Remove the 10px top spacing issue */
  .content-container {
    margin-top: 80px !important;
    padding-top: 0 !important;
  }
  
  main {
    margin-top: 0 !important;
  }
}

.part-sort-modal {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

.sort-section {
  margin-bottom: 1.5rem;
}

.sort-direction {
  margin-bottom: 1.5rem;
}

.direction-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: white;
  cursor: pointer;
}

.direction-button svg {
  transition: transform 0.2s;
}

.direction-button svg.rotated {
  transform: rotate(180deg);
} 
.add-part-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #1a202c;
}

.close-button {
  background: none;
  border: none;
  color: #718096;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.close-button:hover {
  background: #f7fafc;
  color: #4a5568;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #4a5568;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 0.9rem;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.save-button,
.cancel-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.save-button {
  background: #4299e1;
  color: white;
  border: none;
}

.save-button:hover {
  background: #3182ce;
}

.cancel-button {
  background: white;
  border: 1px solid #e2e8f0;
  color: #4a5568;
}

.cancel-button:hover {
  background: #f7fafc;
}

.error-message {
  color: #e53e3e;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: #fff5f5;
  border-radius: 4px;
  border: 1px solid #feb2b2;
}

.subcategory-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.add-subcategory-button {
  padding: 0.5rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.add-subcategory-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.new-subcategory-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

.subcategory-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.save-subcategory-button,
.cancel-subcategory-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-subcategory-button {
  background: #4CAF50;
  color: white;
}

.cancel-subcategory-button {
  background: #e2e8f0;
  color: #4a5568;
} 
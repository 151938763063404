.update-meters-container {
  padding: 20px;
  background-color: #f9fafb;
  min-height: calc(100vh - 80px);
}

.update-meters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.update-meters-table {
  width: 100%;
  overflow-x: auto;
}

.update-meters-table table {
  width: 100%;
  border-collapse: collapse;
}

.update-meters-table th,
.update-meters-table td {
  height: 100%;
  vertical-align: top;
}

.update-meters-table tr {
  height: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.equipment-selection-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.filter-section {
  margin-bottom: 20px;
}

.equipment-list {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.equipment-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.equipment-item:hover {
  background-color: #f5f5f5;
}

.equipment-item.selected {
  background-color: #e3f2fd;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
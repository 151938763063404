.sort-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .sort-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .sort-group {
    margin-bottom: 20px;
  }
  
  .sort-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .sort-options {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .toggle-button {
    flex: 1;
    padding: 8px 12px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease;
  }
  
  .toggle-button:hover {
    background-color: #e0e0e0;
  }
  
  .toggle-button svg {
    font-size: 18px;
  }
  
  .sort-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .sort-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .apply-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .apply-button:hover {
    background-color: #45a049;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f;
  }
.equipment-type-modal {
  background-color: white;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  height: 80vh;  /* Fixed height */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.equipment-type-modal h2 {
  margin: 0 0 20px 0;
}

.equipment-type-modal form {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px); /* Subtract header height */
}

.equipment-type-modal .modal-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
  margin-bottom: 20px;
}

.equipment-type-modal .form-group {
  margin-bottom: 15px;
}

.equipment-type-modal .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.equipment-type-modal .form-group input,
.equipment-type-modal .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.equipment-type-modal .categories-section {
  margin-top: 20px;
}

.equipment-type-modal .categories-table-container {
  height: 200px; /* Fixed height */
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 10px 0;
}

.equipment-type-modal table {
  width: 100%;
  border-collapse: collapse;
}

.equipment-type-modal thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-bottom: 2px solid #ddd;
}

.equipment-type-modal th,
.equipment-type-modal td {
  padding: 8px;
  border: 1px solid #ddd;
}

.equipment-type-modal .form-actions {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 15px 0;
  margin-top: auto;
  border-top: 1px solid #ddd;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.equipment-type-modal button {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.equipment-type-modal button[type="submit"] {
  background-color: #007bff;
  color: white;
  border: none;
}

.equipment-type-modal button[type="submit"]:hover {
  background-color: #0056b3;
} 
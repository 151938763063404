.assign-user-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .assign-user-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888;
  }
  
  .assign-user-modal h4 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .user-list {
    overflow-y: auto;
  }
  
  .user-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .user-option:last-child {
    border-bottom: none;
  }
  
  .user-option button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .user-option button:hover {
    background-color: #45a049;
  }
  
  .no-users-message {
    text-align: center;
    color: #888;
    font-style: italic;
  }
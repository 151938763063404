.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.maintenance-item-modal {
  background: white;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 600px;
  max-height: calc(90vh);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10000;
  overflow: hidden;
}

.maintenance-item-modal h2 {
  margin: 0;
  padding: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  background: white;
  position: sticky;
  top: 0;
  z-index: 10001;
}

.modal-content-wrapper {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.items-section {
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}

.category-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.category-button:hover {
  background: #f7fafc;
  border-color: #cbd5e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-button svg {
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-size: 24px;
}

.category-button span {
  font-size: 0.9rem;
  color: #2d3748;
  font-weight: 500;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid #e2e8f0;
  background: white;
}

.save-button {
  background-color: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.save-button:hover {
  background-color: #1d4ed8;
}

.cancel-button {
  background-color: #e5e7eb;
  color: #374151;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.cancel-button:hover {
  background-color: #d1d5db;
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  border-bottom: 1px solid #e2e8f0;
  background: white;
}

.filter-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.filter-name {
  font-weight: 500;
  color: #1f2937;
}

.filter-description {
  font-size: 0.875rem;
  color: #6b7280;
}

.add-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: none;
  background: none;
  color: #2563eb;
  cursor: pointer;
  transition: color 0.2s;
  min-width: 2.5rem;
}

.add-filter-button:hover {
  color: #1d4ed8;
}

.previously-used-parts,
.available-parts {
  background: white;
  border-radius: 0.375rem;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}

.part-stock {
  font-size: 0.875rem;
  color: #6b7280;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .modal-overlay {
    align-items: flex-start;
  }

  .maintenance-item-modal {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    margin: 0;
  }

  .maintenance-item-modal h2 {
    padding: 1rem;
  }

  .modal-content-wrapper {
    padding: 1rem;
  }
}

/* Ensure no blur on text */
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.selected-parts {
  background: #f8fafc;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e2e8f0;
}

.selected-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background: white;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
}

.selected-part:last-child {
  margin-bottom: 0;
}

.selected-part span {
  font-weight: 500;
  color: #1f2937;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f3f4f6;
  padding: 0.25rem;
  border-radius: 0.375rem;
}

.quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border: none;
  background: white;
  border-radius: 0.25rem;
  color: #2563eb;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.quantity-button:hover {
  background: #f8fafc;
  color: #1d4ed8;
}

.remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border: none;
  background: #fee2e2;
  border-radius: 0.25rem;
  color: #dc2626;
  cursor: pointer;
  transition: all 0.2s;
}

.remove-button:hover {
  background: #fecaca;
}

.quantity-display {
  min-width: 1.5rem;
  text-align: center;
  font-weight: 500;
  color: #4b5563;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #374151;
  margin: 0 0 0.75rem 0;
}

.parts-section {
  margin-top: 1rem;
}

.part-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.part-name {
  font-weight: 500;
  color: #1f2937;
}

.part-description {
  font-size: 0.875rem;
  color: #6b7280;
}

.part-stock {
  font-size: 0.75rem;
  color: #6b7280;
  background: #f3f4f6;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: fit-content;
}
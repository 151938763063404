.reports-container {
  padding: 20px;
}

.report-header {
  margin-bottom: 20px;
}

.report-info {
  margin: 10px 0;
}

.equipment-details {
  margin: 10px 0;
}

.meter-readings {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.fuel-summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.fuel-log-table, .imr-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.fuel-log-table th,
.fuel-log-table td,
.imr-table th,
.imr-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.fuel-log-table th,
.imr-table th {
  background-color: #f5f5f5;
}

.page-number {
  text-align: right;
  margin-top: 20px;
}

@media print {
  .reports-container {
    padding: 0;
  }

  .report-actions {
    display: none;
  }

  .report-controls {
    display: none;
  }

  @page {
    size: landscape;
    margin: 0.5in;
  }
} 
.work-order-list {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 75px); /* Adjust based on your layout */
  overflow-y: auto;
  padding: 5px;
}

.work-order-list h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.work-order-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.work-order-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  overflow: hidden; /* Ensure the stamp doesn't overflow */
}

.work-order-item:hover {
  background-color: #f5f5f5;
}

.work-order-item.selected {
  background-color: #f0f0f0;
}

.work-order-content {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.equipment-id {
  min-width: 20px;
  font-weight: bolder;
  font-size: 1.4em;
}

.work-order-title {
  flex: 1;
  font-size: 0.8em;
}

.date-flag {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 10px;
  
}

.date-flag.red {
  background-color: #ff4444;
}

.date-flag.yellow {
  background-color: #ffbb33;
}

.date-flag.green {
  background-color: #00C851;
}

.work-order-item:hover .date-flag {
  opacity: 0.7;
}

/* Sleek scrollbar styles */
.work-order-list::-webkit-scrollbar {
  width: 8px;
}

.work-order-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.work-order-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.work-order-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.work-order-list {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.stamp {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%) rotate(-10deg);
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  padding: 3px;
  opacity: 0.75;
  pointer-events: none; /* Ensure the stamp doesn't interfere with clicks */
  background-color: white; /* Set background to white */
  border: 3px solid currentColor; /* Use currentColor for border */
  border-radius: 15px;
}

.stamp.closed {
  color: red; /* Set text and border color to red */
}

.stamp.postponed {
  color: blue; /* Set text and border color to blue */
}

.date-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 5px;
}

.date-label {
  font-size: 0.8em;
  color: #666;
  margin-bottom: 2px;
  text-align: right;
  padding-right: 5px;
}

.display-date {
  font-size: 0.9em;
  font-weight: bold;
}

.work-order-actions {
  font-weight: bold;
}

.load-more-trigger {
  padding: 10px;
  text-align: center;
  color: #666;
  font-size: 0.9em;
}

.work-order-list {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  overflow-y: auto;
  height: calc(100vh - 120px); /* Adjust based on your layout */
}

.work-order-group {
  border-bottom: 1px solid #eee;
}

.group-header {
  cursor: pointer;
  background-color: #f8f9fa;
}

.group-header:hover {
  background-color: #e9ecef;
}

.nested-orders {
  padding-left: 20px;
  background-color: #fff;
}

.nested-orders .work-order-item {
  border-bottom: 1px solid #f0f0f0;
}

.nested-orders .work-order-item:last-child {
  border-bottom: none;
}

.work-order-item.nested {
  padding-left: 25px;
}

.work-order-item.nested .work-order-content {
  padding-left: 0;
}

.work-order-item.nested:hover {
  background-color: #f8f9fa;
}
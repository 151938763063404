/* Admin Container and Tabs */
.admin-container {
  padding: 20px;
  background-color: #f9fafb;
  min-height: calc(100vh - 80px);
}

.admin-tabs {
  display: flex;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: sticky;
  top: 0px;
  z-index: 100;
  padding: 0 10px;
  height: 41px;
  min-height: 40px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-tab {
  position: relative;
  padding: 8px 15px;
  cursor: pointer;
  border: none;
  background: none;
  color: #374151;
  transition: all 0.3s ease;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-tab .tab-label {
  display: none;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #374151;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}

.admin-tab:hover .tab-label {
  display: block;
}

.admin-tab:hover {
  background-color: #e0e0e0;
  color: #1f2937;
}

.admin-tab.active {
  background-color: #b1ffc2;
  border-bottom: 2px solid #330864;
  color: #111827;
}

.admin-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  height: calc(100vh - 80px);
  margin-top: 0;
  z-index: 500;
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
  .admin-tabs {
    padding: 0 5px;
    justify-content: space-between;
  }

  .admin-tab {
    padding: 8px;
    min-width: 35px;
  }

  .admin-tab .tab-label {
    display: none;
  }
}

/* User Management Styles */
.user-management {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.user-management-header {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-table {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: auto;
  min-height: 0;
}

.user-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.user-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f2f2f2;
}

.user-table th {
  padding: 12px;
  text-align: left;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

.user-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Set column widths */
.user-table th:nth-child(1),
.user-table td:nth-child(1) { width: 15%; } /* Username */

.user-table th:nth-child(2),
.user-table td:nth-child(2) { width: 15%; } /* Display Name */

.user-table th:nth-child(3),
.user-table td:nth-child(3) { width: 20%; } /* Email */

.user-table th:nth-child(4),
.user-table td:nth-child(4) { width: 25%; } /* Roles */

.user-table th:nth-child(5),
.user-table td:nth-child(5) { width: 25%; } /* Actions */



/* Equipment Management Styles */
.equipment-management {
  height: 100%;
  padding: 20px;
}

.equipment-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Common Form Styles */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Button Styles */
.button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.button-edit {
  background-color: #3498db;
  color: white;
}

.button-delete {
  background-color: #e74c3c;
  color: white;
  font-weight: bold;
}

.button-reset {
  background-color: #f39c12;
  color: white;
}

/* Add User Button */
.add-user-button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-user-button:hover {
  background-color: #45a049;
}



/* Role Management Styles */
.role-management-container {
  padding: 0px;
}

.role-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.role-management-header h2 {
  font-size: 1.5rem;
  color: #333;
}

.add-role-button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.add-role-button:hover {
  background-color: #45a049;
}

.add-role-button svg {
  margin-right: 5px;
}

.role-list {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.role-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-header {
  display: flex;
  background-color: #f2f2f2;
  padding: 12px 10px;
  font-weight: bold;
}

.table-row {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  border-bottom: 1px solid #ddd;
}

.table-cell {
  flex: 1;
  padding: 0 10px;
  word-break: break-word;
}

.admin-row {
  background-color: #fafafa;
}

.default-role {
  color: #e67e22;
  font-weight: bold;
  margin-left: 5px;
}

/* Role Management Styles */
.role-management-container {
  height: calc(100vh - 90px);
  padding: 5px;
}

.role-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.role-list {
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: calc(100% - 90px);
  overflow: auto;
  border: 1px solid #ddd;
}

.role-table {
  border: 1px solid #ddd;
}

.table-header {
  position: sticky;
  top: -1px;
  z-index: 1;
}

/* Location Management Styles */
.location-management {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.location-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-location-button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-location-button:hover {
  background-color: #45a049;
}

.location-table {
  flex: 1;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.location-table table {
  width: 100%;
  border-collapse: collapse;
}

.location-table th,
.location-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.location-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.device-management {
  padding: 20px;
}

.device-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.device-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.device-table th,
.device-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.device-table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.85em;
  font-weight: 500;
}

.status-badge.active {
  background-color: #e6f4ea;
  color: #1e7e34;
}

.status-badge.inactive {
  background-color: #feeced;
  color: #dc3545;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin: 0 4px;
  color: #666;
}

.icon-button:hover {
  color: #333;
}

.icon-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

tr.inactive {
  background-color: #f8f9fa;
  color: #6c757d;
}

.token-warning {
  color: #dc3545;
  font-weight: 500;
  margin-bottom: 16px;
}

.token-display {
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.token-display code {
  font-family: monospace;
  word-break: break-all;
}

.token-display button {
  padding: 4px 8px;
  margin-left: 8px;
  white-space: nowrap;
}

.button-permanent-delete {
  background-color: #dc2626;
  color: white;
  margin-left: 0.5rem;
}

.button-permanent-delete:hover {
  background-color: #b91c1c;
}

.warning-text {
  color: #dc2626;
  font-weight: bold;
  margin-top: 0.5rem;
}

.password-section {
  margin-bottom: 1rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.password-input-container {
  margin-top: 0.5rem;
}

.password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input input {
  width: 100%;
  padding-right: 40px;
}

.show-password-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #666;
}

.show-password-button:hover {
  color: #333;
}

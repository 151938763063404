/* Equipment List Styles */
.equipment-list-container {
  width: 300px;
  min-width: 300px;
  flex-shrink: 0;
  overflow: hidden;
  border-right: 1px solid #ccc;
}

.equipment-list-wrapper {
  height: 100%;
  overflow-y: auto;
  padding: 0 5px;
}

.equipment-list-header {
  margin: 0;
  padding: 8px 5px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  font-size: 1rem;
}

.equipment-list-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.equipment-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  height: 30px;
  overflow: hidden;
}

.equipment-list-item:hover {
  background-color: #f5f5f5;
}

.equipment-list-item.selected {
  background-color: #f0f0f0;
}

/* Equipment Modal Styles */
.equipment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.equipment-modal-container {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  width: 95%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.equipment-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.equipment-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-right: 30px;
  position: relative;
}

.equipment-modal-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.equipment-modal-id {
  font-size: 1.4em;
  font-weight: bold;
}

.equipment-modal-category {
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
}

.equipment-modal-edit-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #666;
}

.equipment-modal-status {
  font-weight: bold;
  font-size: 0.9em;
  min-width: 120px;
  text-align: right;
}

.equipment-modal-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.equipment-modal-field {
  display: flex;
  align-items: center;
  gap: 10px;
}

.equipment-modal-label {
  font-weight: 700;
  min-width: 80px;
}

.equipment-modal-value {
  flex: 1;
}

.equipment-modal-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.equipment-modal-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Meter Reading Styles */
.equipment-meter-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 0;
}

.equipment-meter-checkbox {
  display: flex;
  align-items: center;
}

.equipment-meter-value {
  display: flex;
  align-items: center;
  gap: 8px;
}

.equipment-meter-actions {
  display: flex;
  gap: 4px;
}

/* Action Buttons */
.equipment-modal-actions {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.equipment-modal-save {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.equipment-modal-cancel {
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

/* Equipment Filter Modal Styles */
.equipment-filter-modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
}

.filter-section {
  margin-bottom: 15px;
}

.filter-section label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.filter-section select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.filter-actions button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.filter-actions button:first-child {
  background-color: #007bff;
  color: white;
  border: none;
}

.filter-actions button:not(:first-child) {
  background-color: white;
  border: 1px solid #ddd;
}

/* Equipment Sort Modal Styles */
.equipment-sort-modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .equipment-list-container {
    width: 100%;
    min-width: unset;
    border-right: none;
  }

  .equipment-modal-container {
    width: 95%;
    max-width: 500px;
  }
}

/* Scrollbar Styles */
.equipment-list-wrapper::-webkit-scrollbar {
  width: 6px;
}

.equipment-list-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.equipment-list-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.equipment-list-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Add this if any labels are missing the bold style */
.equipment-modal-body label {
  font-weight: 700;
}

.equipment-field label {
  font-weight: 700;
  min-width: 55px;
}

.meter-field label {
  font-weight: 700;
}

/* Update the equipment field styles */
.equipment-field {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 2px;
}

.equipment-field label {
  font-weight: 700;
  min-width: 55px;
}

.equipment-field label::after {
  content: " ";
}

/* Update meter field styles */
.equipment-meter-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 0;
}

.equipment-modal-label {
  font-weight: 700;
  min-width: 80px;
}

.equipment-modal-label::after {
  content: " ";
}

/* Update field value styles */
.field-value {
  white-space: nowrap;
  margin-left: 5px;
}

/* Add/Update these styles */

.equipment-field-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 4px;
}

.equipment-field-row .equipment-field {
  flex: 1;
  min-width: 150px;
}

.equipment-field {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 2px;
}

.equipment-field label {
  font-weight: 700;
  min-width: 45px;
}

.equipment-field .field-value {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 2px;
}

.equipment-field input {
  flex: 1;
  min-width: 0;
  padding: 4px;
}

/* Update the modal header styles */
.equipment-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-right: 30px;
  position: relative;
}

/* Update edit button styles for both mobile and desktop views */
.equipment-modal-edit {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

/* Mobile view (modal overlay) positioning */
.equipment-modal-overlay .equipment-modal-container .equipment-modal-edit {
  top: 15px;
  right: 15px;
  transform: none;
}

/* Desktop view (inline display) positioning */
.equipment-details .equipment-modal-edit {
  top: 15px;
  right: 15px;
  transform: none;
}

.equipment-modal-edit:hover {
  color: #333;
}

/* Add/Update these styles */

.equipment-meter-field {
  margin-bottom: 8px;
}

.equipment-notes-field {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.equipment-notes-field .field-value {
  margin-left: 0;
  white-space: pre-wrap;
}

/* Update existing meter container styles */
.equipment-meter-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 0;
}

.meter-field {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.meter-value {
  min-width: 60px;
}

.meter-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.meter-actions .icon {
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.meter-actions .history-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 16px;
}

.meter-actions .history-button:hover {
  color: #333;
}

.meter-input {
  width: 80px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.equipment-meter-checkbox {
  display: flex;
  align-items: center;
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Update specific icon styles */
.meter-actions .icon.edit {
  color: #666;
}.meter-actions .icon.edit:hover,
.meter-actions .history-button:hover {
  color: #333;
}

/* Add status select styles */
.status-select {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: inherit;
  width: 100%;
}

.status-select:focus {
  outline: none;
  border-color: #007bff;
}

/* Add styles for status options */
.status-select option[value="In-Service"] {
  color: #4CAF50;
  font-weight: 500;
}

.status-select option[value="Storage"],
.status-select option[value="Maintenance"],
.status-select option[value="Repair"] {
  color: #2196F3;
  font-weight: 500;
}

.status-select option[value="Out-of-Service"],
.status-select option[value="Salvage"] {
  color: #f44336;
  font-weight: 500;
}

.edit-select {
  flex: 1;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: inherit;
}

.edit-select:focus {
  outline: none;
  border-color: #007bff;
}

.edit-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

/* Add these styles to your existing EquipmentStyles.css */
.maintenance-schedule-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.section-title {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 15px;
}

.maintenance-interval {
  margin-bottom: 10px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.maintenance-interval-form {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.maintenance-select {
  min-width: 150px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.maintenance-input {
  width: 100px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.maintenance-actions {
  display: flex;
  gap: 5px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  color: #666;
}

.icon-button:hover {
  color: #333;
}

.icon-button .icon.save {
  color: #4CAF50;
}

.icon-button .icon.cancel {
  color: #f44336;
}

.icon-button .icon.edit {
  color: #2196F3;
}

.icon-button .icon.delete {
  color: #f44336;
}

.add-interval-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: 1px dashed #666;
  padding: 8px 12px;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  margin-top: 10px;
}

.add-interval-button:hover {
  border-color: #333;
  color: #333;
}

.equipment-header {
  display: flex;
  align-items: baseline;
  gap: 0.75rem;
}

.equipment-id {
  font-weight: 600;
  color: #2d3748;
}

.equipment-category {
  color: #718096;
  font-size: 0.9rem;
}

.equipment-parts-section {
  margin-top: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  overflow: hidden;
}

.equipment-parts-section .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f8fafc;
  cursor: pointer;
  user-select: none;
}

.equipment-parts-section .section-header:hover {
  background: #f1f5f9;
}

.equipment-parts-section .section-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #1f2937;
}

.parts-content {
  padding: 1rem;
}

.parts-category {
  margin-bottom: 1.5rem;
}

.parts-category:last-child {
  margin-bottom: 0;
}

.parts-category h4 {
  margin: 0 0 0.75rem 0;
  color: #4b5563;
  font-size: 1rem;
  font-weight: 600;
}

.parts-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.part-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
}

.part-details {
  flex: 1;
  min-width: 0;
}

.part-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.part-name {
  font-weight: 500;
  color: #1f2937;
  font-size: 0.95rem;
}

.part-usage-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  min-width: 140px;
}

.part-quantity {
  color: #059669;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.part-quantity:hover {
  background-color: #f3f4f6;
}

.part-edit-controls {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.part-edit-controls input {
  width: 80px;
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}

.part-edit-controls select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}

.save-edit-button,
.cancel-edit-button {
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.save-edit-button {
  background: #4CAF50;
  color: white;
}

.cancel-edit-button {
  background: #e2e8f0;
  color: #4a5568;
}

.part-item {
  cursor: pointer;
}

.part-item:hover {
  background: #f8fafc;
}

.part-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.part-name {
  font-weight: 600;
  color: #1f2937;
  font-size: 0.95rem;
}

.part-category {
  color: #6b7280;
  font-size: 0.85rem;
}

.part-quantity {
  color: #059669;
  font-size: 0.9rem;
  font-weight: 500;
}

.part-usage {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.usage-count {
  font-size: 0.85rem;
  color: #4b5563;
}

.last-used {
  font-size: 0.85rem;
  color: #6b7280;
}

.parts-category {
  margin-bottom: 1.5rem;
}

.parts-category h4 {
  color: #374151;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #e5e7eb;
}

.parts-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.part-item {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.75rem;
}

.part-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-part-history-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.add-part-history-button:hover {
  background: #45a049;
}

.add-part-history-modal {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.add-part-history-modal h3 {
  margin: 0 0 1.5rem 0;
  color: #1f2937;
}

.part-selection-section {
  margin-bottom: 1.5rem;
}

.part-selection-section label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #4b5563;
}

.part-selection-section select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.part-history-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #4b5563;
}

.form-group input,
.form-group select {
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.add-part-button {
  background: none;
  border: none;
  color: #4CAF50;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.add-part-button:hover {
  background: #e8f5e9;
  color: #2e7d32;
}

.part-quantity {
  color: #059669;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.part-quantity:hover {
  background-color: #f3f4f6;
}

/* Ensure the part edit controls are properly styled */
.part-edit-controls {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.part-edit-controls input {
  width: 80px;
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}

.part-edit-controls select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}


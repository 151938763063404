.part-modal {
  background: white;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-color: #2563eb;
}

.part-modal.inline {
  width: 100%;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
}

.part-modal-content {
  flex: 1;
  overflow-y: auto;
  padding: 0rem;
}

.part-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  background: white;
  position: sticky;
  top: 0px;
  z-index: 10;
}

.header-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.header-info h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #111827;
}

.category-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #f3f4f6;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #4b5563;
}

.header-actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.header-actions .save-button,
.header-actions .cancel-button {
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.header-actions .save-button {
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 0.375rem;
}

.header-actions .save-button:hover {
  background: #1d4ed8;
}

.header-actions .cancel-button {
  background: #f3f4f6;
  color: #4b5563;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.header-actions .cancel-button:hover {
  background: #e5e7eb;
}

.header-actions .edit-button,
.header-actions .close-button {
  padding: 0.5rem;
  background: none;
  border: none;
  color: #6b7280;
  border-radius: 0.375rem;
}

.header-actions .edit-button:hover,
.header-actions .close-button:hover {
  background: #f3f4f6;
  color: #374151;
}

.header-actions .delete-button {
  background: #ef4444;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.header-actions .delete-button:hover {
  background: #dc2626;
}

.detail-section {
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  background: #f9fafb;
}

.detail-section h3 {
  margin: 0 0 1rem 0;
  font-size: 1.125rem;
  color: #111827;
}

.category-display {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.main-category {
  color: #4b5563;
}

.separator {
  color: #9ca3af;
}

.sub-category {
  color: #111827;
  font-weight: 500;
}

.category-selects {
  display: flex;
  gap: 1rem;
}

.select-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.select-group label {
  font-size: 0.875rem;
  color: #4b5563;
}

.inventory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.inventory-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
}

.inventory-item .icon {
  color: #6b7280;
  font-size: 1.25rem;
}

.inventory-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.inventory-content label {
  font-size: 0.75rem;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.inventory-content span {
  color: #111827;
  font-weight: 500;
}

.applications-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.application-group h4 {
  margin: 0 0 0.75rem 0;
  font-size: 1rem;
  color: #4b5563;
}

.application-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.application-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  transition: all 0.2s;
}

.application-item:hover {
  background-color: #f3f4f6;
}

.application-item .icon {
  color: #6b7280;
  font-size: 1.25rem;
}

.application-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.equipment-id {
  font-weight: 500;
  color: #111827;
}

.quantity {
  font-size: 0.875rem;
  color: #6b7280;
}

.notes {
  font-size: 0.875rem;
  color: #4b5563;
  font-style: italic;
}

.no-applications {
  text-align: center;
  color: #6b7280;
  padding: 2rem;
  background: white;
  border-radius: 0.375rem;
  border: 1px dashed #e5e7eb;
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

/* Form Controls */
input, select, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background: white;
  color: #111827;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: #2563eb;
}

/* Buttons */
button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.save-button {
  background: #2563eb;
  color: white;
  border: none;
}

.save-button:hover {
  background: #1d4ed8;
}

.cancel-button {
  background: #f3f4f6;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.cancel-button:hover {
  background: #e5e7eb;
}

.edit-button, .close-button {
  padding: 0.5rem;
  background: none;
  border: none;
  color: #6b7280;
}

.edit-button:hover, .close-button:hover {
  color: #374151;
  background: #f3f4f6;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.add-equipment-button {
  background: none;
  border: none;
  color: #2563eb;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.add-equipment-button:hover {
  background-color: #f3f4f6;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.25rem;
}

.quantity-control input {
  width: 60px;
  padding: 0.25rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}

.add-equipment-modal {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 500px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.edit-application-modal {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 500px;
}

.edit-application-modal h3 {
  margin-bottom: 1.5rem;
}

.edit-application-modal .form-group {
  margin-bottom: 1rem;
}

.edit-application-modal input[disabled] {
  background-color: #f3f4f6;
  cursor: not-allowed;
}

.usage {
  font-size: 0.875rem;
  color: #6b7280;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.delete-button {
  background: none;
  border: none;
  color: #ef4444;
  padding: 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-button:hover {
  background-color: #fee2e2;
} 